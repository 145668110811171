import React from 'react';
/* eslint-disable-next-line no-restricted-imports */
import styled from 'styled-components';

import getFontDetailsByLabel from '@headout/aer/src/tokens/typography';
import { COLORS } from '@headout/fresh-aer';

import Rating from 'Components/common/rating';

import { truncateNumber } from 'Utils/gen';

/* Const */
import TYPE_LABELS from 'Static/typography/labels';

const RatingWrapper = styled.div`
	display: flex;
	margin-bottom: 0.5rem;
	align-items: center;
`;

const RatingLabel = styled.div`
	margin-right: 1.25rem;

	@media (max-width: 768px) {
		margin-right: 1.625rem;
	}
`;

const BarWrapper = styled.div`
	flex: 1 0 auto;
`;

const BarBase = styled.div`
	flex: 1 0 auto;
	margin-right: 1.375rem;
	height: 0.25rem;
	border-radius: 0.25rem;
	background-color: #ebebeb;

	@media (max-width: 768px) {
		margin-right: 1.0625rem;
	}
`;

const BarFill = styled.div`
	height: 100%;
	border-radius: 0.25rem;
	background-color: ${COLORS.TEXT.HEADOUT_CANDY_1};
`;

const RatingCount = styled.div`
	width: 1.5rem;
	${getFontDetailsByLabel(TYPE_LABELS.UI_LABEL_SMALL_HEAVY)};
	text-align: right;
`;

type Props = {
	rating?: number;
	count?: number;
	totalCount?: number;
};

const RatingBar = ({ rating, count, totalCount }: Props) => {
	// @ts-expect-error TS(2532): Object is possibly 'undefined'.
	const percentage = (100 * count) / totalCount;
	const kFormattedCount = truncateNumber(count);
	const displayLength =
		percentage <= 2 && percentage !== 0 ? 2 : Math.min(percentage, 100);

	return (
		<RatingWrapper>
			<RatingLabel data-qa-marker='ratings-bar-label'>
				<Rating
					value={rating}
					fillColor={COLORS.TEXT.HEADOUT_CANDY_1}
				/>
			</RatingLabel>
			<BarWrapper>
				<BarBase>
					<BarFill style={{ width: `${displayLength}%` }} />
				</BarBase>
			</BarWrapper>
			<RatingCount>{kFormattedCount}</RatingCount>
		</RatingWrapper>
	);
};

export default RatingBar;
