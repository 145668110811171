import React from 'react';
/* eslint-disable-next-line no-restricted-imports */
import styled from 'styled-components';

import getFontDetailsByLabel from '@headout/aer/src/tokens/typography';
import { COLORS } from '@headout/fresh-aer';

import Conditional from 'Components/common/conditional';
import LSpan from 'Components/common/localizedTags/localizedSpan';

import { truncateNumber } from 'Utils/gen';

import { StarIcon } from 'Constants/descriptorSvg';
import { strings } from 'Constants/strings';

import colors from 'Static/typography/colors';
import fonts from 'Static/typography/fonts';
import TYPE_LABELS from 'Static/typography/labels';

const Wrapper = styled.div`
	.ratings-count {
		${getFontDetailsByLabel(TYPE_LABELS.UI_LABEL_MEDIUM)};
		color: ${colors.GREY['G3']};
	}

	@media (max-width: 768px) {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 1rem;
	}
`;

export const RatingsAverage = styled.div`
	display: flex;
	align-items: center;
	color: ${COLORS.TEXT.HEADOUT_CANDY_1};
	font-size: 2.25rem;
	font-weight: ${fonts.FONT_WEIGHT.MEDIUM};
	margin-top: -0.625rem;
	gap: 0.3125rem;

	svg {
		transform: translateY(5%);
		align-self: center;
		display: inline-block;
		height: 1.75rem;
		width: 1.75rem;
	}

	@media (max-width: 768px) {
		margin: 0;
	}
`;

const RatingsStarsAndAverage = ({
	averageRating,
	ratingsCount,
	showRatings = true,
}: any) => {
	const kFormattedRatingsCount = truncateNumber(ratingsCount);
	const reviewLabel =
		ratingsCount > 1 ? strings.RATINGS.plural : strings.RATINGS.singular;

	return (
		<Wrapper>
			<RatingsAverage>
				{showRatings && <StarIcon />}
				{showRatings ? averageRating.toFixed(1) : strings.CMN_NEW}
			</RatingsAverage>

			<Conditional if={ratingsCount && showRatings}>
				<LSpan className='ratings-count'>
					{kFormattedRatingsCount} {reviewLabel}
				</LSpan>
			</Conditional>
		</Wrapper>
	);
};

export default RatingsStarsAndAverage;
