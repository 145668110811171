import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import dynamic from 'next/dynamic';
import cookies from 'nookies';
/* eslint-disable-next-line no-restricted-imports */
import styled, { css } from 'styled-components';

import getFontDetailsByLabel from '@headout/aer/src/tokens/typography';
import { Button } from '@headout/eevee';
import { css as pixieCss } from '@headout/pixie/css';

import type { TReviewCountries } from 'Components/common/aggregatedCountries/interface';
import Conditional from 'Components/common/conditional';
import RatingBar from 'Components/common/ratingBar';
import RatingsStarsAndAverage from 'Components/common/ratingsStarsAndAverage';
import ReviewElement from 'Components/common/reviewElement';
import TravelerMedia from 'Components/common/travelerMediaTiles';
import type {
	TFetchReviewsCallback,
	TProps as TTravelerMediaProps,
} from 'Components/common/travelerMediaTiles/interface';

import { Chevron } from 'Assets/commonSvgs';

import {
	getExperiencePageEventCommonProperties,
	trackEvent,
} from 'Utils/analytics';
import { getABTestingVariantBySandboxId } from 'Utils/experiments/experimentUtils';
import { getReviewsPageUrl } from 'Utils/productUtils';
import { getTravelerMedia } from 'Utils/stateUtils';

import { fetchTravelerMediaByTgid } from 'Thunks/travelerMedia';

import { ANALYTICS_PROPERTIES } from 'Constants/analytics';
import {
	DEFAULT_TOP_REVIEWS_COUNT,
	HSID_DEFAULT_VALUE,
} from 'Constants/constants';
import { EXPERIMENT_NAMES } from 'Constants/experiments';
import { strings } from 'Constants/strings';

import colors from 'Static/typography/colors';
import TYPE_LABELS from 'Static/typography/labels';

const AggregatedCountries = dynamic(
	() =>
		/* webpackChunkName: 'AggregatedCountries' */ import(
			'Components/common/aggregatedCountries'
		),
);

const Wrapper = styled.div`
	margin-bottom: 1.25rem;
`;

const Ratings = styled.div<{ $hideBottomBorder?: boolean }>`
	padding: 1.25rem 0;
	display: flex;
	justify-content: space-between;
	${({ $hideBottomBorder }) =>
		!$hideBottomBorder &&
		css`
			border-bottom: 1px dashed ${colors.GREY.E2};
		`};
`;

const RatingsGraph = styled.div`
	font-weight: 400;
	padding-left: 2.5rem;
	flex: 1 1 auto;
	max-width: 28.375rem;
	margin-right: 2%;
`;

const ReviewElementsWrapper = styled.div`
	width: 98%;
`;

const SortContainer = styled.div`
	margin-top: 1.5rem;
	cursor: pointer;
	position: relative;
`;

const SortTitleContainer = styled.div`
	display: flex;
	align-items: center;

	.title {
		${getFontDetailsByLabel(TYPE_LABELS.UI_LABEL_LARGE_HEAVY)};
	}

	.sort-option {
		${getFontDetailsByLabel(TYPE_LABELS.PARAGRAPH_LARGE)};
		margin: 0 0.5rem;
	}

	svg {
		width: 0.75rem;
	}
`;

const SortInputContainer = styled.div`
	display: flex;
	flex-direction: column;
	background: ${colors.WHITE};
	width: 16.5rem;
	position: absolute;
	top: 2.75rem;
	z-index: 100;
	padding: 1rem;
	border: 1px solid ${colors.GREY.E2};
	border-radius: 0.5rem;
	box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 2px 8px rgba(0, 0, 0, 0.1);

	.sortInputField {
		width: 100%;
		margin-bottom: 1rem;
		display: flex;

		:last-child {
			margin-bottom: 0;
		}
	}

	.sortInputField > label {
		width: 100%;
		cursor: pointer;
		${getFontDetailsByLabel(TYPE_LABELS.UI_LABEL_MEDIUM)}
	}

	.radioContainer {
		position: relative;
	}

	.radioContainer input {
		cursor: pointer;
		display: none;
	}

	.radioContainer .circle {
		display: inline-block;
		width: 1.25rem;
		height: 1.25rem;
		border-radius: 50%;
		border: 1px solid ${colors.GREY.C4};
		position: absolute;
		right: 0;
	}

	.radioContainer:hover .circle {
		border-color: ${colors.PURPS};
	}

	.radioContainer input:checked + .circle {
		background: ${colors.PURPS};
		border: none;
	}

	.radioContainer input:checked + .circle:before {
		content: '';
		width: 0.5rem;
		height: 0.5rem;
		background: ${colors.WHITE};
		border-radius: 50%;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
`;

const buttonWrapper = pixieCss({
	marginTop: 'space.40',
	width: 'fit-content',
	'& a': {
		color: 'semantic.text.grey.1 !important',
	},
});

type Props = {
	product?: any;
	params?: any;
	lang?: string;
	userReviews?: any;
	collapsed?: boolean;
	compactHeader?: boolean;
	showCount?: number;
	totalReviews?: number;
	onShowAllClick?: () => void;
	showSortOption?: boolean;
	travelerMediaData?: TTravelerMediaProps;
	fetchTravelerMedia?: TFetchReviewsCallback;
	reviewCountries?: TReviewCountries;
};

const ReviewSection = ({
	product,
	params,
	collapsed,
	compactHeader,
	showCount: initialShowCount,
	totalReviews: totalReviewsCount,
	onShowAllClick,
	showSortOption,
	travelerMediaData,
	fetchTravelerMedia,
	lang,
	userReviews,
	reviewCountries,
}: Props) => {
	const [showCount, setShowCount] = useState(initialShowCount || 3);
	const [paginationStep] = useState(20);
	const [showSortOptionsDropdown, setShowSortOptionsDropdown] =
		useState(false);
	const [sortOptionSelected, setSortOptionSelected] = useState(
		strings.MOST_RELEVANT,
	);
	const [numberOfTimesShowMoreClicked, setNumberOfTimesShowMoreClicked] =
		useState(0);
	const [reviews, setReviews] = useState(userReviews);
	const [isExperimentTracked, setIsExperimentTracked] = useState(false);
	const [experimentVariant, setExperimentVariant] = useState<string | null>(
		null,
	);
	const cookie = cookies.get();
	const hsid = cookie['h-sid'];

	const { id } = product;

	const handleFreeSpaceClick: EventListener = e => {
		// @ts-ignore
		if (!e?.target?.closest('.sort-container')) {
			setShowSortOptionsDropdown(false);
		}
	};

	useEffect(() => {
		document.addEventListener('click', handleFreeSpaceClick, true);
		return () => {
			document.removeEventListener('click', handleFreeSpaceClick, true);
		};
	}, []);

	useEffect(() => {
		if (!hsid || hsid === HSID_DEFAULT_VALUE || isExperimentTracked) return;

		if (!isExperimentTracked) {
			setIsExperimentTracked(true);

			const ABTestingVariant = getABTestingVariantBySandboxId(
				EXPERIMENT_NAMES.REVIEW_MEDIA_VISIBILITY,
				hsid,
				true,
			);

			setExperimentVariant(ABTestingVariant);
		}
	}, [hsid]);

	const handleSortOptions = (e: any) => {
		const sortType = e.target.dataset.sort;
		switch (sortType) {
			case 'LOW_TO_HIGH':
				const ascendeingReviews = reviews
					.slice()
					.sort((a: any, b: any) => a.rating - b.rating);
				setReviews(ascendeingReviews);
				trackEvent({
					eventName: 'Reviews Sorted',
					[ANALYTICS_PROPERTIES.SORT_TYPE]: sortType,
				});
				break;

			case 'HIGH_TO_LOW':
				const descendingReviews = reviews
					.slice()
					.sort((a: any, b: any) => b.rating - a.rating);
				setReviews(descendingReviews);
				trackEvent({
					eventName: 'Reviews Sorted',
					[ANALYTICS_PROPERTIES.SORT_TYPE]: sortType,
				});
				break;

			case 'MOST_RELEVANT':
				setReviews(userReviews);
				trackEvent({
					eventName: 'Reviews Sorted',
					[ANALYTICS_PROPERTIES.SORT_TYPE]: sortType,
				});
				break;

			default:
				return;
		}

		setSortOptionSelected(e.target.value);
		setShowSortOptionsDropdown(false);
	};

	const onShowMoreClick = () => {
		const count =
			showCount + paginationStep > userReviews.length
				? userReviews.length
				: showCount + paginationStep;
		setShowCount(count);
		trackEvent({
			eventName: 'Show More Reviews Clicked',
			...getExperiencePageEventCommonProperties(product),
			'Click Count': numberOfTimesShowMoreClicked + 1,
		});
		setNumberOfTimesShowMoreClicked(numberOfTimesShowMoreClicked + 1);
	};

	const getProcessedReviews = (showCount = 3) => {
		if (!showSortOption) {
			if (!userReviews) return [];

			return userReviews.slice(0, showCount);
		}

		return reviews.slice(0, showCount);
	};

	if (!product) {
		return null;
	}
	const { reviewsDetails } = product;
	const { ratingsCount, ratingsSplit, averageRating } = reviewsDetails;

	// @ts-expect-error TS(7034): Variable 'ratingBarComps' implicitly has type 'any... Remove this comment to see the full error message
	const ratingBarComps = [];

	const ratingData = {
		5: 0,
		4: 0,
		3: 0,
		2: 0,
		1: 0,
		...ratingsSplit,
	};
	Object.keys(ratingData)
		.reverse()
		.forEach(ratingStr => {
			const rating = Number(ratingStr);
			const count = Number(ratingData[ratingStr]);

			if (rating === 0) {
				return;
			}
			ratingBarComps.push(
				<RatingBar
					key={rating}
					rating={rating}
					count={count}
					totalCount={ratingsCount}
				/>,
			);
		});

	const reviewSectionComps = getProcessedReviews(showCount).map(
		(userReview: any) => (
			<ReviewElement
				key={userReview.id}
				experimentVariant={experimentVariant}
				currentLanguage={params.lang}
				compactHeader={compactHeader}
				{...userReview}
			/>
		),
	);

	const totalReviews = totalReviewsCount || userReviews.length;

	const reviewsPageUrl = getReviewsPageUrl({
		product,
		paramLang: params.lang,
	});

	const displaySortOptions = () => {
		setShowSortOptionsDropdown(!showSortOptionsDropdown);

		trackEvent({
			eventName: 'Sort By Clicked',
		});
	};

	const handleShowAllClick = (e: any) => {
		e.stopPropagation();
		onShowAllClick?.();
	};

	return (
		<Wrapper>
			<Ratings
				$hideBottomBorder={
					!userReviews?.length || !!reviewCountries?.countries?.length
				}
			>
				<RatingsStarsAndAverage
					ratingsCount={ratingsCount}
					averageRating={averageRating}
				/>

				<RatingsGraph data-qa-marker='ratings-graph'>
					{/* @ts-expect-error TS(7005): Variable 'ratingBarComps' implicitly has an 'any[]... Remove this comment to see the full error message */}
					{ratingBarComps}
				</RatingsGraph>
			</Ratings>

			<Conditional
				if={
					!!reviewCountries?.countries?.length &&
					reviewCountries.countries.length > 5
				}
			>
				<AggregatedCountries reviewCountries={reviewCountries!} />
			</Conditional>

			<Conditional if={travelerMediaData}>
				<TravelerMedia
					{...travelerMediaData!}
					lang={lang}
					tgid={id}
					fetchReviews={fetchTravelerMedia}
					isDesktop
				/>
			</Conditional>

			<Conditional if={showSortOption}>
				<SortContainer className='sort-container'>
					<SortTitleContainer onClick={displaySortOptions}>
						<span className='title'>{strings.SORT_BY}: </span>
						<span className='sort-option'>
							{sortOptionSelected}{' '}
						</span>
						<Chevron orientation='down' />
					</SortTitleContainer>

					<Conditional if={showSortOptionsDropdown}>
						<SortInputContainer>
							<div className='sortInputField'>
								<label
									className='radioContainer'
									htmlFor={strings.MOST_RELEVANT}
								>
									{strings.MOST_RELEVANT}
									<input
										type='radio'
										id={strings.MOST_RELEVANT}
										name={strings.MOST_RELEVANT}
										value={strings.MOST_RELEVANT}
										checked={
											sortOptionSelected ===
											strings.MOST_RELEVANT
										}
										data-sort='MOST_RELEVANT'
										onChange={handleSortOptions}
									/>
									<span className='circle'></span>
								</label>
							</div>

							<div className='sortInputField'>
								<label
									className='radioContainer'
									htmlFor={strings.RATING_HIGH_TO_LOW}
								>
									{strings.RATING_HIGH_TO_LOW}
									<input
										type='radio'
										name={strings.RATING_HIGH_TO_LOW}
										id={strings.RATING_HIGH_TO_LOW}
										value={strings.RATING_HIGH_TO_LOW}
										checked={
											sortOptionSelected ===
											strings.RATING_HIGH_TO_LOW
										}
										data-sort='HIGH_TO_LOW'
										onChange={handleSortOptions}
									/>
									<span className='circle'></span>
								</label>
							</div>

							<div className='sortInputField'>
								<label
									className='radioContainer'
									htmlFor={strings.RATING_LOW_TO_HIGH}
								>
									{strings.RATING_LOW_TO_HIGH}
									<input
										type='radio'
										name={strings.RATING_LOW_TO_HIGH}
										id={strings.RATING_LOW_TO_HIGH}
										value={strings.RATING_LOW_TO_HIGH}
										checked={
											sortOptionSelected ===
											strings.RATING_LOW_TO_HIGH
										}
										data-sort='LOW_TO_HIGH'
										onChange={handleSortOptions}
									/>
									<span className='circle'></span>
								</label>
							</div>
						</SortInputContainer>
					</Conditional>
				</SortContainer>
			</Conditional>
			<Conditional if={reviews && reviews.length > 0}>
				<>
					<ReviewElementsWrapper>
						{reviewSectionComps}
					</ReviewElementsWrapper>
					<Conditional if={showCount >= DEFAULT_TOP_REVIEWS_COUNT}>
						{collapsed ? (
							<Conditional if={showCount < totalReviews}>
								<div className={buttonWrapper}>
									<Button
										as='anchor'
										variant='secondary'
										btnType='black'
										href={reviewsPageUrl as string}
										target='_blank'
										rel='nofollow noreferrer noopener'
										primaryText={
											strings.RP_SHOW_ALL_REVIEWS
										}
										onClick={handleShowAllClick}
										data-qa-marker='show-all-reviews-button'
										data-qa-id='show-all-reviews-button'
									/>
								</div>
							</Conditional>
						) : (
							<Conditional if={showCount < totalReviews}>
								<div className={buttonWrapper}>
									<Button
										as='button'
										variant='secondary'
										btnType='black'
										primaryText={strings.PPPR_SHOW_MORE_CTA}
										onClick={onShowMoreClick}
										data-qa-marker='show-more-reviews-button'
										data-qa-id='show-more-reviews-button'
									/>
								</div>
							</Conditional>
						)}
					</Conditional>
				</>
			</Conditional>
		</Wrapper>
	);
};

const mapStateToProps = (state: any, ownProps: any) => {
	const {
		params: { id, lang },
	} = ownProps;
	const travelerMediaData = getTravelerMedia(
		state,
		id,
	) as TTravelerMediaProps;

	return {
		travelerMediaData,
		lang,
	};
};

const mapDispatchToProps = (dispatch: any) => ({
	fetchTravelerMedia(id: string | number, offset = 0, limit = 5) {
		dispatch(fetchTravelerMediaByTgid({ tgId: id, offset, limit }));
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(ReviewSection);
